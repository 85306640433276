import React from 'react';
import Main from '../containers/Layout';
import toolkitTermsAndConditionsContent from '../../content/pages/toolkit-terms-and-conditions.yml';
import ToolkitTermsContainer from '@/containers/Toolkit-terms-and-conditions';

export interface ToolkitTermsAndConditionsPageContent {
  header: {
    title1: string;
    title2: string;
    subtitle1: string;
    subtitle2: string;
  };
  body: string;
}
const TermsAndConditionsPage: React.FunctionComponent = () => {
  const content =
    toolkitTermsAndConditionsContent as unknown as ToolkitTermsAndConditionsPageContent;
  return (
    <Main>
      <ToolkitTermsContainer content={content} />
    </Main>
  );
};

export default TermsAndConditionsPage;
